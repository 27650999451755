import React, { Component } from 'react'
import Navigacija from '../../containers/navbar';
import News from '../../components/news';
import Footer from '../../containers/footer';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import Picture7 from '../../images/picture7.png';
import NewsComponent from '../../components/news';
import newsPicture1 from '../../images/news1.png';
import newsPicture2 from '../../images/news2.png';
import newsPicture3 from '../../images/news3.png';
import newsPicture4 from '../../images/news4.png';
import ReactPaginate from 'react-paginate';
import PageWithLayout from '../../containers/page';
import MenuComponent from '../../components/menuComponent';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'


const links = [
    {
        _id: 1,
        link: '/novosti/aktuelna-dešavanja',
        name: 'Aktuelna dešavanja'
    },
    {
        _id: 2,
        link: '/novosti/kutak-za-medije',
        name: 'Kutak za medije'
    }
];

const articles = [
    {
        _id: 1,
        image: newsPicture1,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Donacija EuroExpress brze pošte za djecu u Dnevnim centrima “Gnijezdo”'
    },
    {
        _id: 2,
        image: newsPicture2,
        allias: '/novosti/detalji',
        date: '15. Oktobar, 2018.',
        title: 'OBAVJEŠTENJE: Nova lokacija distributivnog centra Sarajevo'
    },
    {
        _id: 3,
        image: newsPicture3,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Obavještenje: Neradni dan u Mostaru i Vitezu'
    },
    {
        _id: 4,
        image: newsPicture4,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Novo: Slovenija – drumski transport'
    },
    {
        _id: 5,
        image: newsPicture4,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Novo: Slovenija – drumski transport'
    },
    {
        _id: 6,
        image: newsPicture2,
        allias: '/novosti/detalji',
        date: '15. Oktobar, 2018.',
        title: 'OBAVJEŠTENJE: Nova lokacija distributivnog centra Sarajevo'
    },
    {
        _id: 7,
        image: newsPicture3,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Obavještenje: Neradni dan u Mostaru i Vitezu'
    },
    {
        _id: 8,
        image: newsPicture4,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Novo: Slovenija – drumski transport'
    },
    {
        _id: 9,
        image: newsPicture4,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Novo: Slovenija – drumski transport'
    },

];



class NewsPage extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0, items: [] };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        fetch('https://euroexpress.novamedia.agency/mediaNews', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                items: result
            })
            console.log(result);
        })


    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        const settings = {

            previousLabel: '',
            previousLinkClassName: 'no-ma',
            nextLabel: '',
            breakLabel: '...',
            breakClassName: 'break-me',
            pageCount: 9,
            marginPagesDisplayed: this.state.width <= 767 ? 2 : 3,
            pageRangeDisplayed: this.state.width <= 767 ? 1 : 2,
            onPageChange: (page) => { this.updateFilter('page', page.selected + 1) },
            pageClassName: 'all',
            pageLinkClassName: 'allLink',
            containerClassName: 'pagination',
            subContainerClassName: 'pages pagination',
            activeClassName: 'active',

        }
        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Novosti</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Novosti</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Aktuelna dešavanja</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="3" lg="3">
                            <div className="desktopSidemenu">
                                <h6>Meni</h6>
                                <MenuComponent links={links}/>
                            </div>

                                                        <div className="desktopSidemenu contactDesktopSidemenu">
                                
                                                        
 
                                <h6>Kontakt za medije:</h6>
                                <p>Služba marketing<br/>
                                <a href="mailto:marketing@euroexpress.ba">marketing@euroexpress.ba</a>
                                <br/>
                                Tel: 051/</p>
                            </div>

                        </Col>
                        <Col xl="9" lg="9" md="12" xs="12">
                            <h6>Kutak za medije</h6>
                            <p>Saopštenja za javnost</p>
                            <Row>
                                {this.state.items.map((item, idx) => {
                                    return (
                                        <Col xl="4" lg="4" md="6" xs="12" key={item._id} className="newsBoxWrapper2 newsBoxWrapper3">
                                            <Link to={`/novosti/${item._id}`}>
                                                <div className="newsBox">
                                                    <img src={item.image} className="img-fluid" />
                                                    <div className="textBox">
                                                        <h6>{item.name}</h6>
                                                        <p>{moment.unix(item.timestamp).format('DD. MMMM, YYYY')}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                })}
                            </Row>
                            {/*<ReactPaginate {...settings} />*/}

                        </Col>

                    </Row>

                </Container>
                <News />
            </div>
        )
    }
}

export default PageWithLayout(NewsPage)
